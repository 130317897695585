<template>
  <div>
    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <el-select
          class="select-primary pagination-select mb-2"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <el-select
          :placeholder="$t('USERS.IS_STAFF')"
          v-model="selectedIsStaff"
          v-if="$currentUser().is_staff"
        >
          <el-option :value="null" :label="$t('USERS.ALL_IS_STAFF')" />
          <el-option :value="true" :label="$t('COMMON.YES')" />
          <el-option :value="false" :label="$t('COMMON.NO')" />
        </el-select>

        <role-selector
          v-if="
            !filterRole && $currentUserCan($permissions.PERM_VIEW_ANY_ROLES)
          "
          @roleChanged="(roleId) => (selectedRole = roleId)"
        />

        <base-input
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          :placeholder="`${$t('COMMON.LOCATIONS')}`"
        >
          <locations-selector
            @locationsChanged="(locations) => (selectedLocations = locations)"
          />
        </base-input>

        <organization-selector
          v-if="
            !filterOrganization &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          "
          @organizationChanged="
            (organizationId) => (selectedOrganization = organizationId)
          "
        />

        <reseller-selector
          v-if="
            !filterReseller &&
            !filterOrganization &&
            $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
          "
          @resellerChanged="(resellerId) => (selectedReseller = resellerId)"
        />

        <div class="search-wrapper">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fas fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
      </div>
      <el-table
        class="align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="users"
        @sort-change="sortChange"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column label="" min-width="150px">
          <template v-slot="{ row }">
            <img
              v-if="row.profile_image"
              :src="row.profile_image"
              class="avatar rounded-circle mr-3"
            /><br />
            <span>
              <icon-check :checked="!!row.active" />
              {{ $t("USERS.ACTIVE") }}
            </span>
            <br />
            <span>
              <icon-check :checked="!!row.is_staff" />
              {{ $t("USERS.IS_STAFF") }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.NAME')"
          prop="firstname"
          sortable="custom"
          min-width="350px"
        >
          <template v-slot="{ row }">
            <span>{{ `${row.firstname} ${row.lastname}` }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.EMAIL')"
          prop="email"
          sortable="custom"
          min-width="350px"
        />
        <el-table-column
          v-if="
            !filterRole && $currentUserCan($permissions.PERM_VIEW_ANY_ROLES)
          "
          :label="$t('COMMON.ROLE')"
          prop="roles.name"
          min-width="250px"
        >
          <template v-slot="{ row }">
            <span v-if="row.roles[0]">
              <router-link
                :to="{ name: 'View Role', params: { id: row.roles[0].id } }"
              >
                {{ row.roles[0].name }}
              </router-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.LOCATIONS')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          min-width="250px"
        >
          <template v-slot="{ row }">
            <locations :locations="row.allowedLocations" />
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            !filterOrganization &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          "
          :label="$t('COMMON.ORGANIZATION')"
          min-width="250px"
        >
          <template v-slot="{ row }">
            <organization :organization="row.organization" />
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            !filterReseller &&
            !filterOrganization &&
            $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
          "
          :label="$t('COMMON.RESELLER')"
          prop="reseller"
          min-width="250px"
        >
          <template v-slot="{ row }">
            <reseller :reseller="row.reseller" />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.CREATED_AT')"
          prop="created_at"
          sortable="custom"
          min-width="200px"
        >
          <template v-slot="{ row }">
            {{ $timeZoneDateFormat(row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column min-width="100px" align="center" fixed="right">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              :content="$t('COMMON.VIEW')"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_VIEW_USERS)"
            >
              <a
                type="text"
                @click="viewUser(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-eye"></i>
              </a>
            </el-tooltip>

            <el-tooltip
              :content="$t('COMMON.EDIT')"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_EDIT_USERS)"
            >
              <a
                type="text"
                @click="editUser(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-edit"></i>
              </a>
            </el-tooltip>

            <el-tooltip
              content="Delete"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_DELETE_USERS)"
            >
              <a
                type="text"
                @click="deleteUser(row.id)"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-trash"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div
      slot="footer"
      class="mt-5 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap table-footer"
    >
      <div class="">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { BasePagination } from "@/components";
import requestErrorMixin from "@/mixins/request-error-mixin";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";
import IconCheck from "@/components/IconCheck.vue";
import RoleSelector from "@/components/RoleSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";

export default {
  name: "user-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    ResellerSelector,
    IconCheck,
    RoleSelector,
    LocationsSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterRole: {
      type: String,
      default: null,
      description: "Role id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      users: [],
      loading: true,
      selectedRole: null,
      selectedOrganization: null,
      selectedReseller: null,
      selectedIsStaff: null,
      selectedLocations: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterRole: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedRole: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedIsStaff: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : { sort: "-created_at" }),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "roles,reseller,organization,allowedLocations",
        };

        if (this.filterRole) {
          params = {
            ...params,
            filter: { ...params.filter, roles: this.filterRole },
          };
        }
        if (this.selectedRole) {
          params = {
            ...params,
            filter: { ...params.filter, roles: this.selectedRole },
          };
        }
        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.filterReseller && !this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }
        if (this.selectedReseller && !this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: this.selectedReseller,
            },
          };
        }
        if (this.selectedIsStaff !== null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              is_staff: this.selectedIsStaff,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        await this.$store.dispatch("users/list", params);
        this.users = this.$store.getters["users/list"];
        this.total = this.$store.getters["users/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteUser(id) {
      const confirmation = await swal.fire({
        title: this.$t("USERS.DELETE_THIS_USER"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("users/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("USERS.USER_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async viewUser(row) {
      this.$router.push({
        name: "View User",
        params: { id: row.id },
      });
    },

    async editUser(row) {
      this.$router.push({
        name: "Edit User",
        params: { id: row.id },
      });
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
